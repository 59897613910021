<template>
  <div>
    <div class="c-row c-horizontal-center ui-mb-4">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <p-breadcrumb />
      </div>
    </div>

    <router-view
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import PBreadcrumb from '@/components/p-breadcrumb';

export default {
  components: {
    PBreadcrumb,
  },
};
</script>
