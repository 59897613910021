<template>
  <div
    v-if="shouldShow"
    class="c-d-flex ui-items-center c-text-b3 c-text-gray"
  >
    <template v-for="(item, index) of items">
      <router-link
        :to="item.to"
        :key="item.label"
        class="capitalize-first-letter"
        :tag="(index === items.length - 1) ? 'span' : 'a'"
      >
        {{ item.label }}
      </router-link>

      <icon
        v-if="index !== items.length - 1"
        :key="`arrow-${item.label}`"
        name="arrow-right"
        width="16"
        color="var(--c-color-gray-300)"
        class="ui-mx-2"
      />
    </template>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';

export default {
  components: { Icon },
  computed: {
    shouldShow() {
      // Breadcrumb só deve ser exibido caso esteja a mais de um nível de navegação
      return this.items.length > 1;
    },
    items() {
      return this.$route.matched
        .flatMap(r => ({
          label: r.meta.breadcrumb?.label,
          to: r.name ? { name: r.name } : r.meta.breadcrumb?.to,
        }))
        .filter(r => r.label != null && r.to != null);
    },
  },
};
</script>

<style scoped>
.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}
</style>
